<!--  -->
<template>
  <div style="background-color: #f7f7f7">
    <div class="title_div">
      <img
        src="../assets/icon/back.png"
        style="height:20px;width:20px;margin-left:10px;margin-top:1.5vw;"
        @click="goBack()"
      />
      <div>车队收费明细</div>
      <div style="width:40px;"></div>
    </div>
    <div
      v-for="(item, index) in carList"
      :key="index"
      style="margin-top: 1.3vw;padding-bottom:7px"
    >
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 93%;
          height: 5vh;
          background-color: #e6e6e6;
          margin-top: 1vw;
          margin-left: 3.5%;
          border-radius: 20px;
        "
        :style="
          index % 2 != 0
            ? 'background-color:#FFFFFF ;'
            : 'background-color:#E6E6E6;'
        "
      >
        <div style="margin-left: 4vw">{{ item.name }}</div>
        <div style="display: flex; align-items: center">
          <span style="margin-right: 4vw">{{ item.money }}</span>
          <img
            src="../assets/icon/hanshui.png"
            alt=""
            style="width: 5vw; height: 5vw; margin-right: 3.5vw"
          />
        </div>
      </div>
    </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
        carList: [
       
      ],
    }
  },
 mounted(){

  this.zhiban(this.$route.query.name,this.$route.query.time)
  

 },
  methods: {
    zhiban(a,b){
      this.$axios
        .post(this.$api.feeRegistrationDetail, {
          name:a,
          time:b,
          type:1
        })
        .then((res) => {
          console.log(res.data.data,"")
          this.carList=res.data.data
          let sum = 0;
      this.carList.forEach((ele) => {
        sum = sum + ele.money;
      });
      this.carList.push({
        name: "合计",
        money: sum,
      });
        });

    },
  goBack(){
    this.$router.go(-1);
  }

  }
}

</script>
<style  scoped>
.title_div {
  height: 9vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  color: #ffffff;
  /* line-height:12vh;
  text-align: center;
  font-size:4.6vw; */
;
  /* background: #3F98EB; */
  font-size: 4.6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>